<template>
  <div :class="`shareModal`" v-if="showShareModal">
    <div class="shareModal__content" data-aos="fade-up">
      <button class="shareModal__close-button" @click="showShareModal = false">
        <iconic name="closeIcon" />
      </button>
      <h3>Compartir</h3>
      <p class="text">Comparte con tus amigos este contenido</p>
      <div class="shareModal__content-icons">
        <button class="button-icon"><iconic name="copyIcon" /></button>
        <button class="button-icon"><iconic name="instagram" /></button>
        <button class="button-icon"><iconic name="facebook" /></button>
        <button class="button-icon"><iconic name="tiktok" /></button>
        <button class="button-icon"><iconic name="twitter" /></button>
        <button class="button-icon"><iconic name="youtube" /></button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      showShareModal: false,
    };
  },

  methods: {
    openShareModal() {
      this.showShareModal = true;
    },
  },
};
</script>
<style lang="scss">
.shareModal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: visibility 0.5s ease-in-out;
  background: rgba(0, 0, 0, 0.4);

  &.show {
    visibility: visible;
  }

  &__content {
    border-radius: 12px;
    background: #fff;
    position: relative;
    width: 450px;
    padding: 40px;

    h3 {
      color: $primary_color;
      text-align: center;
      font-family: "BebasKai", sans-serif;
      font-size: 40px;
      text-transform: uppercase;
    }
    .text {
      font-size: 20px;
      text-align: center;
      margin-top: 22px;
    }
    &-icons {
      display: grid;
      align-items: center;
      gap: 20px 5px;
      grid-template-columns: repeat(3, 80px);
      justify-items: center;
      align-items: center;
      margin-top: 20px;
      place-content: center;
      .button-icon {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        background: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 2.44086px 8.54301px 0px rgba(0, 0, 0, 0.26);
        .iconic {
          color: #fff;
          margin: 0;
          font-size: 20px;
        }
      }
    }
  }
  &__close-button {
    position: absolute;
    right: 20px;
    .iconic {
      font-size: 16px;
      font-weight: 300;
      color: $primary_color;
    }
  }
  @include Desktop {
    &__content {
      width: 400px;
    }
  }
}
</style>
