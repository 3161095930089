<template>
  <div class="post-modal" v-if="showModal" @click="closeModal">
    <div class="post-modal__content" data-aos="fade-up">
      <div class="post-modal__content-header">
        <button
          class="post-modal__close-button"
          @click="closeModal($event, true)"
        >
          <iconic name="closeIcon" />
        </button>
      </div>
      <div class="post-modal__content-body">
        <div class="post-modal__content-video">
          <VideoPlayer :videoId="url" v-if="isYoutube" />
          <Video :src="url" controls v-else></Video>
        </div>
        <div class="post-modal__content-post">
          <div class="post-modal__content-post-text">
            <div class="post-modal__text modal-margin">
              <h2 class="title-post">{{ title }}</h2>
              <h3 class="subtitle-post">{{ subtitle }}</h3>
              <p id="post-text"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="post-modal__content-footer">
        <div class="post-modal__footer-comments-button">
          <button
            type="button"
            class="modal-margin btnOpenComments"
            @click="toggleShowComments($event, true)"
          >
            <iconic name="comment" />
            <span>2</span>
          </button>
        </div>
      </div>
      <shareModal ref="shareModal" />
    </div>
  </div>
</template>
<script>
import VideoPlayer from "../web/videoPlayer.vue";
import shareModal from "./sharePostModal.vue";
import WebCastComment from "../web/WebCastComments.vue";
export default {
  components: {
    VideoPlayer,
    shareModal,
    WebCastComment,
  },
  data: function () {
    return {
      liked: false,
      isYoutube: true,
      url: "",
      title: "",
      subtitle: "",
      showModal: false,
      showShareModal: false,
      likeCount: 2,
      likeAnimation: false,
      showComments: false,
      comments: [
        {
          author: "Andrea Pérez",
          textMessage:
            "¡Qué risa me dio escuchando las anécdotas divertidas de estas modelos! La moda también tiene su lado cómico, y estas chicas lo demuestran.",
          reply: false,
        },
        {
          author: "Elena Rodríguez",
          textMessage: "¡Totalmente de acuerdo, Pedro!",
          reply: true,
        },
        {
          author: "María López",
          textMessage:
            "Estas modelos no solo son hermosas, ¡también son increíblemente graciosas! Sus anécdotas son una muestra de que el humor está en todas partes.",
          reply: false,
        },
      ],
    };
  },
  methods: {
    toggleLike() {
      this.liked = !this.liked;
      this.likeAnimation = true;
      setTimeout(() => {
        this.likeAnimation = false;
        this.likeCount = this.liked ? this.likeCount + 1 : this.likeCount - 1;
      }, 100);
    },
    renderText(text) {
      setTimeout(() => {
        document.getElementById("post-text").innerHTML = text;
      }, 10);
    },
    openYoutubeModal({ url, text }, isYoutube = true, openComments = false) {
      if (url) {
        this.showModal = true;
        this.url = url;
        this.isYoutube = isYoutube;
        this.title = text.title;
        this.subtitle = text.subtitle;
        this.renderText(text.text);
        if (openComments) {
          setTimeout(() => {
            document.querySelector(".btnOpenComments").click();
          }, 500);
        }
        document.documentElement.style.overflow = "hidden";
        document.body.style.overflow = "hidden";
      }
    },
    openShareModal() {
      this.$refs.shareModal.openShareModal();
    },
    closeModal(evt, isButtonClicked = false) {
      if (isButtonClicked || evt.target.classList.contains("post-modal")) {
        this.showModal = false;
        document.documentElement.style.overflow = "";
        document.body.style.overflow = "";
      }
    },
    toggleShowComments(evt, flag) {
      this.$refs.WebCastComment.toggleShowComments(evt, flag);
    },
  },

  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
  beforeDestroy() {
    document.documentElement.style.overflow = "";
    document.body.style.overflow = "";
  },
};
</script>
<style lang="scss" scoped>
.post-modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in;
  font-size: 14px;
  overflow: hidden;

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes likeAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes likeCountAnimation {
    0% {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
    30% {
      transform: translateY(-20px) scale(1.2);
      opacity: 1;
    }
    100% {
      transform: translateY(-40px) scale(1.4);
      opacity: 0;
    }
  }

  a {
    color: #000;
  }
  .title-post,
  .subtitle-post {
    background: transparent;
    color: $primary-color;
    margin-top: 0;
  }
  .title-post {
    font-size: 28px;
  }
  .subtitle-post {
    font-size: 25px;
    margin-bottom: 10px;
  }
  button {
    .iconic {
      font-weight: bold;
      font-size: 25px;
      margin-right: 6px;
    }
    span {
      color: #fff;
      font-size: 18px;
    }
  }
  .modal-margin {
    margin-left: 20px;
    margin-right: 20px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 95%;
    width: 95%;
    background: #fff;
    border-radius: 12px;
    position: relative;
    overflow: hidden;

    &-body {
      display: flex;
      flex-direction: column;
      overflow: auto;
      flex-grow: 1;
    }
    &-post {
      display: flex;
      flex-direction: column;
      height: 55%;
      &-text {
        overflow: auto;
        padding: 15px 0;
      }
    }
    &-video {
      flex-grow: 1;
      background: #000;
    }
    &-buttons {
      background: #000;
      order: -1;
      padding: 12px 0;
      .iconic {
        color: #fff;
        font-size: 25px;
      }
      .post-modal__register-button {
        display: none;
      }
      .post-modal__buttons {
        &-content {
          display: flex;
          align-items: center;
        }
        &-reactions {
          display: flex;
          align-items: center;
          font-family: "BebasKai", sans-serif;
          gap: 20px;
          .liked {
            animation: likeAnimation 0.2s ease-in-out;
            .iconic {
              color: red;
            }
          }
        }
      }
    }
    &-footer {
      visibility: hidden;
      .post-modal__footer {
        &-comments-button {
          padding: 10px 0;
          width: 100%;
          height: 100%;
          background: #fff;
          border-radius: 0 0 10px 10px;
          justify-self: flex-start;
          button {
            span {
              color: #000;
            }
          }
        }
      }
    }
    &-comments {
      position: absolute;
      width: 100%;
      height: 100%;
      visibility: hidden;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      transition: visibility 0.3s ease-in-out;
      &-content {
        transform: translateY(100%);
        height: 50%;
        width: 100%;
        background: #fff;
        transition: transform 0.3s ease-in-out;
      }

      &.show {
        visibility: visible;
        background: rgba(0, 0, 0, 0.4);
        display: flex;
      }
      &.show > &-content {
        transform: translateY(0%);
      }
      .post-modal__comments {
        &-header {
          padding: 12px 20px;
          h3 {
            text-align: center;
          }
          button {
            position: absolute;
          }
        }
        &-body,
        &-footer {
          img {
            width: 40px;
            height: 40px;
          }
        }
        &-body {
          margin-top: 20px;
          height: 235px;
          min-height: 100px;
          margin-bottom: 150px;
          overflow: auto;
          .post-modal__comment {
            display: flex;
            gap: 10px;
            margin-bottom: 30px;
            margin-right: 5px;
            .author {
              font-weight: bold;
              margin-bottom: 5px;
            }
            .reply {
              margin-top: 10px;
              font-weight: bold;
            }
            &.reply-message {
              margin-left: 50px;
            }
          }
        }
        &-footer {
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          gap: 10px;
          padding: 20px 0;
          background: #fff;
          input {
            width: 100%;
            height: 40px;
            padding: 10px;
            border-radius: 22px;
            border: 1px solid gray;
          }
          .inputs-sendMessage {
            display: flex;
            gap: 10px;
          }
        }
      }
      @include MobileSmall {
        &-content {
          height: 50%;
        }
        .post-modal__comments {
          &-body {
            height: 190px;
            margin-bottom: 20px;
          }
        }
      }
    }

    &-header {
      background: #000;
      padding: 20px 0;
      position: relative;
      border-radius: 12px 12px 0 0;
      button {
        position: absolute;
        top: 10px;
        right: 10px;
        .iconic {
          font-size: 16px;
          color: $primary-color;
        }
      }
    }

    &-video {
      height: 200px;
      overflow: hidden;
      video {
        width: 100%;
        max-height: 100%;
      }
    }
  }
  @include Desktop {
    button {
      span {
        color: #000;
      }
    }
    &__content {
      height: 80%;
      width: 80%;
      &-body {
        flex-direction: row;
        position: relative;
      }
      &-video {
        width: 60%;
        height: 100%;
        position: absolute;
        z-index: 20;
      }
      &-post {
        width: 40%;
        height: 100%;
        justify-content: space-between;
        position: absolute;
        right: 0;
        padding-top: 20px;
        overflow: hidden;
      }
      &-buttons {
        order: 1;
        background: transparent;
        padding: 20px 0;
        .post-modal__buttons {
          &-content {
            justify-content: space-between;
          }
          &-reactions {
            gap: 10px;
          }
        }
        .iconic {
          color: #000;
        }
        .post-modal__register-button {
          display: block;
          background: $primary_color;
          padding: 7px 15px;
          color: #fff;
          border-radius: 20px;
          text-transform: uppercase;
          transition: 0.3s ease-in-out;
          font-size: 14px;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      &-footer {
        display: none;
      }
      &-header {
        background: transparent;
        position: absolute;
        width: 100%;
        height: 60px;
        top: 0;
        z-index: 20;
      }
      &-comments {
        &-content {
          box-shadow: -2px 7px 12px 0px rgba(0, 0, 0, 0.75);
          width: 40%;
          height: 70%;
        }
        .post-modal__comments {
          &-body {
            height: 440px;
          }
        }
      }
      &-share {
        &-content {
          width: 400px;
        }
      }
    }
  }
}
</style>
