<template>
  <div>
    <div id="youtube-player"></div>
  </div>
</template>

<script>
export default {
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    videoId: {
      type: String,
      default: "8wS-xAWP9x0",
    },
  },

  data() {
    return {};
  },
  mounted() {
    this.createPlayer();
  },
  methods: {
    createPlayer() {
      // Obtener el ancho y alto del contenedor padre
      const container = this.$el.parentElement;
      const containerWidth = container.clientWidth || 500;
      const containerHeight = container.clientHeight || 500;
      const configs = {
        constrols: 1,
      };
      if (this.preview) {
        configs["autoplay"] = 1;
      }
      new YT.Player("youtube-player", {
        height: containerHeight.toString(),
        width: containerWidth.toString(),
        videoId: this.videoId,
        playerVars: configs,
        events: {},
      });
    },
  },
};
</script>
